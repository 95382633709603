import React, { Component } from "react";
import { Elements, StripeProvider, CardElement } from "react-stripe-elements";
import { loadStripe } from "@stripe/stripe-js";
import Header from "./components/header.js";
import Footer from "./components/footer.js";
import Donate from "./components/donate.js";
import DonationOptions from "./components/donation-options.js";
import LearnMoreButton from "./components/learn-more.js";
import SocialComponent from "./components/social-component.js";
import Checkout from "./pages/page-donate.js";
import { Link, withRouter } from "react-router-dom";
import heroImage from "./images/header_logo.png";
import "./App.scss";
const data = require("./data/data.json");

class App extends Component {
  renderBody = () => {
    return (
      <div>
        {data.body.map((paragraph) => {
          return <p>{paragraph}</p>;
        })}
      </div>
    );
  };

  render() {
    return (
      <div>
        <Header />
        <div className="topTitle">
          <h1>{data.title}</h1>
        </div>
        <div className="row">
          <div className="double-column">
            <div className="column">
              <div className="section-main">
                <div className="section-main-title">
                  <img src={heroImage}></img>
                  <div style={{ minWidth: "300px" }}></div>
                </div>

                <div className="section-main-description">
                  <p>
                    One of our core values at TechnoMile is Stronger Together –
                    we know that our people are the heart of our company. It’s
                    why we celebrate successes together, and why we demonstrate
                    empathy, compassion, and care when challenges arise for our
                    teammates. The TechnoMile Dream On Program is our employee
                    compassion fund, a key way that we can support our friends
                    and colleagues when they’re in need. TechnoMile will match
                    dollar-for-dollar the funds collected to help our employees
                    through difficult and trying situations. TechnoMile cares
                    and encourages others to do the same – we’re stronger
                    together!
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="section-donate">
              <Donate />
              <DonationOptions />
              <SocialComponent />
              <div class="section-donate-disclaimer">
                *While we work to ensure accuracy, the stats indicated on this
                page may not be an exact reflection of actual activity.
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
